import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./blogs.module.css";
import { withRouter } from "react-router-dom";
import { deleteBlogAction, getBlogsAction } from "./redux/actions";
import { BlogPost } from "../../shared/model/BlogPost";
import Panel from "../../shared/ui/Panel/Panel";

type Props = {
  loadBlogs: Function;
  blogs: any; // TODO fix this
  deleteBlog: Function;
  history: any;
};
class BlogListScreen extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleDeleteBlog = this.handleDeleteBlog.bind(this);

    const { loadBlogs } = this.props;

    loadBlogs();
  }

  handleDeleteBlog(id: string) {
    return () => {
      const { deleteBlog } = this.props;
      console.log("deteting " + id);
      deleteBlog(id);
    };
  }

  render() {
    const { blogs, history } = this.props;
    console.log(blogs);

    if (blogs.isLoading) {
      return <div>Loading</div>;
    }
    if (blogs.isError) {
      return <div>Looks like an error accured</div>;
    }
    if (blogs.data === null || blogs.data === undefined) {
      return <div>Loading</div>;
    }
    return (
      <div>
        <div className={styles.title}>
          <h1>Stuff i write about</h1>
        </div>
        {blogs.data.map((item: BlogPost) => {
          return (
            <Panel
              key={item.id}
              style={{
                backgroundImage: `linear-gradient(90deg,var(--element) 55%, transparent), url(${item.imageUrl})`,
              }}
              onClick={() => {
                history.push(`/blog/${item.blogId}`);
              }}
              stylesOverrride={styles.blog}
            >
              <div className={styles.content}>
                <div className={styles.blogTitle}>{item.name}</div>
                <div className={styles.date}>
                  Posted: {item.dateCreated.toDate().toDateString()}
                </div>
              </div>
            </Panel>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  blogs: state.blogs,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadBlogs: () => dispatch(getBlogsAction()),
  deleteBlog: (id: string) => dispatch(deleteBlogAction(id)),
  dispatch,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlogListScreen)
);
