import React, { useCallback, useState } from "react";
import Button from "../../../../shared/ui/Buttons/Button";
import Input from "../../../../shared/ui/Input/Input";
import styles from "./Contact.module.css";

import TelegramLogo from "../../../../assets/Telegram_logo.svg";
import GithubLogo from "../../../../assets/githubLogo.svg";
import LinkedInLogo from "../../../../assets/LinkedIn.svg";
import GitLabLogo from "../../../../assets/gitlab.svg";
import { db } from "../../../../shared/firebase";

const Contact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const submit = useCallback(() => {
    if (!name || !message || !email) {
      return;
    }
    const data = {
      name,
      email,
      message,
    };
    db.collection("contacts")
      .add(data)
      .then(() => {
        setName("");
        setEmail("");
        setMessage("");
        setSuccess(true);
      });
  }, [name, email, message]);
  const linkTo = (link: string) => {
    return () => {
      window.open(link);
    };
  };
  return (
    <div>
      <div className={styles.contactStatement}>
        <div>Wanna talk? Contact me directly</div>
      </div>
      {!success ? (
        <div className={styles.form}>
          <div className={styles.formItem}>
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className={styles.formItem}>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className={styles.formItem}>
            <Input
              height={200}
              multiple
              placeholder="Message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
          <div className={styles.submit}>
            <Button onClick={submit}>Submit</Button>
          </div>
        </div>
      ) : (
        <div className={styles.sent}>Sent 🚀 </div>
      )}

      <div className={styles.socialStatement}>
        <div>Or use any of my social media accounts</div>
      </div>
      <div className={styles.socialMediaList}>
        <img
          alt=""
          onClick={linkTo("https://www.linkedin.com/in/marinpavic")}
          className={styles.socialMediaItem}
          src={LinkedInLogo}
        />
        <img
          alt=""
          onClick={linkTo("https://github.com/Pig3on")}
          className={styles.socialMediaItem}
          src={GithubLogo}
        />
        <img
          alt=""
          onClick={linkTo("https://gitlab.com/mpavic553")}
          className={styles.socialMediaItem}
          src={GitLabLogo}
        />
        <img
          alt=""
          onClick={linkTo("https://t.me/Pig3on")}
          className={styles.socialMediaItem}
          src={TelegramLogo}
        />
      </div>
    </div>
  );
};

export default Contact;
