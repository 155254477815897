import React from "react";
import styles from "./Panel.module.css";

type Props = {
  width?: number | string;
  stylesOverrride?: any;
  onClick?: (event: any) => void;
  style?: React.CSSProperties;
};
const Panel: React.FC<Props> = ({
  children,
  width = "100%",
  stylesOverrride,
  onClick,
  style,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.panel} ${stylesOverrride}`}
      style={{ width, ...style }}
    >
      {children}
    </div>
  );
};

export default Panel;
