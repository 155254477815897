import React, { useEffect, useState } from "react";
import Panel from "../../../../shared/ui/Panel/Panel";
import styles from "./Technology.module.css";

const technology = [
  {
    name: "Typescript",
    usage: 90,
  },
  {
    name: "React Native",
    usage: 90,
  },
  {
    name: "Javascript",
    usage: 80,
  },
  {
    name: "Electron",
    usage: 80,
  },
  {
    name: "React",
    usage: 80,
  },

  {
    name: "C#/.NET",
    usage: 80,
  },
  {
    name: "Node",
    usage: 70,
  },
  {
    name: "Angular",
    usage: 60,
  },
];
const Technology: React.FC = () => {
  const [useRealUsage, setUseRealUsage] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setUseRealUsage(true);
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <div className={styles.container}>
      <div className={styles.title}>Stuff i use</div>
      {technology.map((tech) => {
        return (
          <Panel
            key={tech.name}
            width={`${useRealUsage ? tech.usage : 20}%`}
            stylesOverrride={styles.techItem}
          >
            <div>{tech.name}</div>
            <div>{tech.usage}%</div>
          </Panel>
        );
      })}
    </div>
  );
};

export default Technology;
