import React from "react";
import styles from "./Avatar.module.css";

type Props = {
  src: string;
  width: number | string;
  height: number | string;
};
const Avatar: React.FC<Props> = ({ src, width, height }) => {
  return (
    <img
      className={styles.avatar}
      src={src}
      alt="Avatar picture"
      width={width}
      height={height}
    />
  );
};

export default Avatar;
