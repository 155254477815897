import React, { Component } from "react";
import styles from "./HeaderScreen.module.css";

import HeaderButton from "../Buttons/HeaderButton";

const cvDownloadUrl =
  "https://firebasestorage.googleapis.com/v0/b/zhoonpository-fc3c8.appspot.com/o/CV%2FMarinPavic_CV.pdf?alt=media&token=2bd92888-db93-4211-8769-56cdd6881e3e";

const HeaderScreen: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <HeaderButton path="/" text="Home" />
        <div style={{ flex: 1 }} />
        <HeaderButton path="/blog" text="Blog" />
        <HeaderButton
          text="Resume"
          onClick={() => {
            window.open(cvDownloadUrl);
          }}
        />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default HeaderScreen;
