import React, { ChangeEvent } from "react";
import Panel from "../Panel/Panel";
import styles from "./Input.module.css";

type Props = {
  placeholder: string;
  multiple?: boolean;
  height?: number;
  value: string;
  onChange: (event: ChangeEvent<any>) => void;
};
const Input: React.FC<Props> = ({
  placeholder,
  multiple,
  height,
  value,
  onChange,
}) => {
  return (
    <Panel stylesOverrride={styles.container}>
      {multiple ? (
        <textarea
          style={{ height }}
          value={value}
          onChange={onChange}
          className={styles.input}
          placeholder={placeholder}
        />
      ) : (
        <input
          value={value}
          onChange={onChange}
          className={styles.input}
          placeholder={placeholder}
        />
      )}
    </Panel>
  );
};

export default Input;
