import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import './App.css';

import HeaderScreen from './shared/ui/header/HeaderScreen';
import BlogScreen from './modules/blogs/BlogScreen';
import BlogsListScreen from './modules/blogs/BlogListScreen';
import HomeScreen from './modules/home/HomeScreen';

class App extends Component {

  render() {
    return (
    <div >
        <HeaderScreen>
              <Switch>
              <Route exact path='/' component={HomeScreen} />
              <Route exact path='/blog' component={BlogsListScreen} />
              <Route exact path='/blog/:id' component={BlogScreen} />
            </Switch>
         </HeaderScreen>
    </div>
     
    );
  }
}


export default App;
