const config = {
  apiKey: "AIzaSyA7WXxzMls22PEGwX0DCG3Z2W7htUA3Xi4",
  authDomain: "zhoonpository-fc3c8.firebaseapp.com",
  databaseURL: "https://zhoonpository-fc3c8.firebaseio.com",
  projectId: "zhoonpository-fc3c8",
  storageBucket: "zhoonpository-fc3c8.appspot.com",
  messagingSenderId: "1001468522375",
  appId: "1:1001468522375:web:4b2a20e5ced8a6a325ad22",
  measurementId: "G-94514XFLEJ"
}

export default config;
