import React, { useEffect, useState } from "react";
import Panel from "../../../../shared/ui/Panel/Panel";

import styles from "./HistoryReel.module.css";

type Props = {
  company: string;
  role: string;
  tech: string;
};
const history = [
  {
    company: "Altrady",
    role: "Contract Frontend Developer",
    tech: "#React #ReactNative #Electron #Crypto",
    paddingRight: "0%",
    paddingLeft: "65%",
  },
  {
    company: "Freelance, Various Companies",
    role: "Frontend Developer",
    tech: "#React #ReactNative",
    paddingRight: "0",
    paddingLeft: "35%",
  },
  {
    company: "Five Agency",
    role: "React/Native Developer",
    tech: "#React #ReactNative #Firebase",
    paddingRight: "35%",
    paddingLeft: "35%",
  },
  {
    company: "King ICT",
    role: "Fullstack .NET Developer",
    tech: "#DotNet #ReactNative #MongoDB",
    paddingRight: "65%",
    paddingLeft: "0%",
  },
];
const Info: React.FC<Props> = ({ company, role, tech }) => {
  return (
    <div className={styles.info}>
      <div className={styles.companyTitle}>{company}</div>
      <div className={styles.role}>{role}</div>
      <div className={styles.tech}>{tech}</div>
    </div>
  );
};

const HistoryReel: React.FC = () => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTrigger(true);
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <div className={styles.container}>
      <div className={styles.title}>Where i worked</div>
      {history.map((historyPoint) => {
        const { paddingLeft, paddingRight } = historyPoint;
        return (
          <div
            key={historyPoint.company}
            className={styles.element}
            style={trigger ? { paddingLeft, paddingRight } : undefined}
          >
            <Panel>
              <Info
                company={historyPoint.company}
                role={historyPoint.role}
                tech={historyPoint.tech}
              />
            </Panel>
          </div>
        );
      })}
    </div>
  );
};

export default HistoryReel;
