import { db } from "../../../shared/firebase"

export const BLOGS_LOADING = 'BLOGS_LOADING'
export const BLOGS_SUCCESS= 'BLOGS_SUCCESS'
export const BLOGS_ERROR= 'BLOGS_ERROR'

export const BLOG_LOADING = 'BLOG_LOADING'
export const BLOG_SUCCESS= 'BLOG_SUCCESS'
export const BLOG_FAILED= 'BLOG_FAILED'
export const BLOG_CLEAR = 'BLOG_CLEAR';

//#region thunks
export function getBlogsAction(){
    return async dispatch => {
        try {
            const postsCollection = await db.collection('posts').where("deleted", "==", false).get()
            const posts = [];
            postsCollection.forEach((doc)=>{
                posts.push({blogId: doc.id, ...doc.data()});
            }) 
            dispatch(fetchBlogsSuccess(posts));
        }catch(e) {
            dispatch(fetchBlogsFaliure(e.message))
        }
    }
}
export function deleteBlogAction(id){
    return async dispatch => {
        await db.collection('posts').doc(id).update({
            deleted: true,
        })
        dispatch(getBlogsAction())
    }
}

export function getBlogAction(id){
    return async dispatch => {
     try {
        dispatch(getBlogBeginAction()); 
        const blog = await db.collection('posts').doc(id).get();
        if(blog.exists){
            dispatch(getBlogSuccessAction({blogId: id, ...blog.data()}));
        } else {
            dispatch(getBlogErrorAction("No blog with this ID"))
        }
        
     }catch(e){
        dispatch(getBlogErrorAction(e.message))
     }
    }
}

//#endregion

function fetchBlogsLoading(){
    return {
        type: BLOGS_LOADING
    }
}


function fetchBlogsSuccess(response){
    return {
        type: BLOGS_SUCCESS,
        payload:response
    }
}

function fetchBlogsFaliure(error){
    return {
        type: BLOGS_ERROR,
        payload: error
    }
}



export function saveBlogAction(blog){
    return dispatch =>{
        db.collection("posts").add(blog);
    }
}


function getBlogBeginAction(){
    return {
        type: BLOG_LOADING
    }
}


function getBlogSuccessAction(response){
    return {
        type: BLOG_SUCCESS,
        payload:response
    }
}

function getBlogErrorAction(error){
    return {
        type: BLOG_FAILED,
        payload: error
    }
}

export function clearBlogAction(action){
    return {
        type: BLOG_CLEAR,
    }
}