
import { BLOGS_ERROR,BLOGS_LOADING,BLOGS_SUCCESS, BLOG_LOADING, BLOG_SUCCESS, BLOG_FAILED, BLOG_CLEAR } from "./actions";

const initialState = {
   isLoading:false,
   isDone:false,
   isError:false,
   error:null,
   data: [],
   selectedBlog: null
};

export default function(state = initialState, action){
  switch(action.type){
      case  BLOGS_LOADING:
       return {
           ...state,
           data: [],
           isLoading:true,
       }
       case BLOGS_SUCCESS:
       return {
           ...state,
           isLoading:false,
            isDone:true,
            isError:false,
           data: action.payload
       }
       case BLOGS_ERROR:
       return {
           ...state,
            isLoading:false,
            isDone:false,
            isError:true,
            error:action.payload
       }
       case  BLOG_LOADING:
       return {
           ...state,
           selectedBlog: null,
           isLoading:true,
           isDone:false,
           isError:false
       }
       case BLOG_SUCCESS:
       return {
           ...state,
           selectedBlog: action.payload,
           isLoading:false,
           isDone:true,
           isError:false
       }
       case BLOG_FAILED:
       return {
            ...state,
            isLoading:false,
            isDone:false,
            isError:true, 
            error: action.payload
       }
       case BLOG_CLEAR:
          return {
            ...state,
             isLoading:false,
             isDone:false,
             isError:false,
            selectedBlog:null
        }
     default:
     return state;
  }
}