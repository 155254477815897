import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./blogscreen.module.css";
import { withRouter } from "react-router-dom";

import { Markdown } from "../../shared/ui/Markdown/Markdown";
import { useEffect } from "react";
import { useState } from "react";
import { getBlogFile } from "../../shared/firebase/actions";
import { Blog } from "../../shared/model/BlogPost";
import { getBlogAction } from "./redux/actions";

export interface Props {
  blog: Blog;
  history: any;
  networkState: {
    isLoading: boolean;
    isError: boolean;
    isDone: boolean;
  };
  match?: any;
  getBlog?: (id: any) => {};
}
const BlogScreen: React.FC<Props> = ({
  blog,
  networkState,
  match,
  getBlog,
}) => {
  const [blogFile, setBlogFile] = useState("");

  useEffect(() => {
    const id = match.params.id == null ? 0 : match.params.id;
    // @ts-ignore
    getBlog(id);
  }, []);
  useEffect(() => {
    async function getBlogFileRequest() {
      if (blog) {
        const document = await getBlogFile(blog.blogFile);
        setBlogFile(document);
      }
    }
    getBlogFileRequest();
  }, [blog]);
  if (networkState.isLoading) {
    return null;
  }
  if (networkState.isError) {
    return <div>Is Error</div>;
  }
  if (networkState.isDone && blog) {
    return <Markdown source={blogFile} />;
  }
  return <div>Unable to load</div>;
};

const mapStateToProps = (state: any) => ({
  blog: state.blogs.selectedBlog,
  networkState: {
    isLoading: state.blogs.isLoading,
    isDone: state.blogs.isDone,
    isError: state.blogs.isError,
    error: state.blogs.error,
  },
});

const mapDispatchToProps = (dispatch: (id: any) => {}) => ({
  getBlog: (id: string) => dispatch(getBlogAction(id)),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlogScreen)
);
