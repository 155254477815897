import React, { Component } from "react";
import App from "../App";
import configureStore from "./configureStore";
import { Provider } from "react-redux";
import { Router } from "react-router";

const { store, history } = configureStore();

class Setup extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    );
  }
}

export default Setup;
