import { applyMiddleware,compose,  createStore } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import thunk from "redux-thunk";

import { createBrowserHistory } from "history";
import rootReducer from '../redux'
const history = createBrowserHistory();

const middleware = [
    routerMiddleware(history),
    thunk
];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export default function configureStore(){
     const enhancer = composeEnhancer(applyMiddleware(...middleware));
    
    const store = createStore(connectRouter(history)(rootReducer),enhancer);

    return {store,history};
}