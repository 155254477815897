import { Blog } from "../model/BlogPost";
import { db, storage } from "./firebaseEntity";

function downloadFile(downloadUrl: string) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'text';
      xhr.onload = () => {
        resolve(xhr.response);
      };
      xhr.onerror = (e) => {
        reject(xhr.status);
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    });
  }

export async function getBlogFile(id: string): Promise<any> {
    const fileRef = storage.ref(`posts/${id}.md`);
    const downloadUrl = await fileRef.getDownloadURL();
    const file = await downloadFile(downloadUrl);
    return file;
}

export async function saveBlog(blog: {
    data: Blog;
    markdown: string;
  }): Promise<any> {
    const documentFileName = Date.now();
    const blogData = {
      ...blog.data,
      contentId: documentFileName,
    };
    await db.collection('posts').add(blogData);
    const storageRef = storage.ref(`posts/${documentFileName}.md`);
    storageRef.putString(blog.markdown);
  }
  