import React, { Component } from "react";
import logo from "../../assets/ZhoonWithName.svg";
import styles from "./styles.module.css";
import Panel from "../../shared/ui/Panel/Panel";
import Avatar from "./components/Avatar";
import HistoryReel from "./components/HistoryReel/HistoryReel";
import Technology from "./components/Technology/Technology";
import Contact from "./components/Contact/Contact";

class HomeScreen extends Component {
  state = {};

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.profile}>
          <Avatar
            src={
              "https://ca.slack-edge.com/T02JLE4BC-UNWTLBHDJ-fb15090689d7-512"
            }
            width={300}
            height={300}
          />
          <div className={styles.info}>
            <h1>Hi, i'm Marin "Zhoon" Pavic</h1>
            <h2>Developer 💻  | Nature Enthusiast 🌱 | Bunny Owner 🐰🐰</h2>
          </div>
        </div>
        <HistoryReel />
        <Technology />
        <Contact />
      </div>
    );
  }
}

export default HomeScreen;
