import React from "react";
import styles from "./Button.module.css";

type Props = {
  onClick: (event: any) => void;
};
const Button: React.FC<Props> = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className={styles.button}>
      {children}
    </div>
  );
};

export default Button;
