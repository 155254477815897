import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./HeaderButton.module.css";
type Props = {
  text: string;
  path?: string;
  onClick?: (event: any) => void;
};
const HeaderButton: React.FC<Props> = ({ text, path, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleOnMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);
  const handleOnMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);
  return (
    <Link
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={onClick}
      className={styles.button}
      style={{ textDecoration: "none", color: "white" }}
      to={path || ""}
    >
      <div>{text}</div>
      <div className={styles.nibble} style={{ opacity: isHovering ? 1 : 0 }} />
    </Link>
  );
};
export default HeaderButton;
